import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Icon from "../utilities/icons"
import { SideInfoBox } from "../components/styles/SideInfoBox"
import {
  ServicesGrid,
  ServicesCardMain,
  ServicesCard,
} from "../components/styles/GridStyles"
import SEO from "../components/seo"
import remark from 'remark'
import remarkHTML from 'remark-html'
import paragraphs from "lines-to-paragraphs"

const toHTML = value => remark()
                            .use(remarkHTML)
                            .processSync(value)
                            .toString()


function compare( a, b ) {

  var a_sort_order = a.node.frontmatter.sort_order;
  var b_sort_order = b.node.frontmatter.sort_order;

  if ( a_sort_order < b_sort_order ){
    return -1;
  }
  if ( a_sort_order > b_sort_order ){
    return 1;
  }
    return 0;
  }
  

                          

const Locations = (props) => {

  const content_data =
  props.data.intros.edges[0].node.childMarkdownRemark.frontmatter;

  const locations_data = props.data.locations;
  locations_data.edges.sort( compare );

  return (
    <Layout>
      <SEO
        title="Locations |  MJ Electrical | Electriciasn in Newark"
        description="Locations covered by MJ Electrical - Electrician in Newark on Trent and surrounding areas. Covering Nottinghamshire, Lincolnshire, Leicestershire, Derbyshire"
      />
      <div class="standard-page-container">
        <div className="inner-container">
          <h1>Locations</h1>
          <div
              dangerouslySetInnerHTML={{
                __html: toHTML(content_data.opening_para),
              }}
            />

          <div className="two-column-layout-wrapper">
            <div className="two-column-layout-item_wide ">
              <h3 className="subheading">Areas Covered</h3>
              <ServicesGrid noBottomStuff="true">

              {locations_data.edges.map((item) => (
                
                <ServicesCard key={item.node.id}>
                  <div className="service-icon">
                    <Icon name="map_check" width="32" color="#ffffff" />
                  </div>
                  <div className="service-content">
                    <h4>{item.node.frontmatter.title}</h4>
                    <div
                      dangerouslySetInnerHTML={{
                      __html: toHTML(item.node.frontmatter.location_details),
                    }}
                  />
                  </div>
                </ServicesCard>
              ))
            }
              </ServicesGrid>
            </div>
            <div className="two-column-layout-item_narrow ">
              <SideInfoBox className="global-box-shadow">
                <h3 className="sidebox-title">Quick Contact</h3>
                <ul className="contact-box-list">
                  <li>
                    <b>Phone / Text:</b> 07963 168251
                  </li>
                  <li>
                    <b>Email: </b>
                    <a href="mailto:info@mjelectric.co.uk">
                      {" "}
                      info@mjelectric.co.uk
                    </a>
                  </li>
                  {/*<li>
                    <b>Facebook: </b>
                    <a href="">
                      <Icon
                        name="facebook"
                        color="#3C5A99"
                        width="16"
                        height="16"
                      />
                    </a>
                  </li>*/}
                </ul>
              </SideInfoBox>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Locations


export const query = graphql`
  query Locations {
  locations: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "data/locations/.*.md$/"}, frontmatter: {}}, sort: {order: ASC, fields: frontmatter___title}) {
    edges {
      node {
        id
        frontmatter {
          title
          location_details
          sort_order
        }
      }
    }
  }
  intros: allFile(filter: {name: {eq: "location_intro_content"}}) {
    edges {
      node {
        childMarkdownRemark {
          id
          html
          frontmatter {
            opening_para
          }
        }
      }
    }
  }
}

`
